export default {
  methods: {
    emailRules() {
      return [(v) => /^$|.+@.+\..+/.test(v) || this.$t('validate.emailNotValid')];
    },
    phoneRules() {
      return [
        (v) => /^[0-9]*$/.test(v) || this.$t('validate.numbersOnly'),
        (v) => (v && (v.length >= 10) || v.length === 0) || this.$t('validate.phoneLength'),
      ];
    },
    rfcRules() {
      return [(v) => (v && (v.length >= 12) || v.length === 0) || this.$t('validate.rfcLength')];
    },
    postalCodeRules() {
      return [
        (v) => /^[0-9]*$/.test(v) || this.$t('validate.numbersOnly'),
        (v) => (v && (v.length === 5) || v.length === 0) || this.$t('validate.postalCodeLength'),
      ];
    },
    urlRules() {
      return [
        (v) => (/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(v) ||
          v.length === 0) || this.$t('validate.urlNotValid'),
      ];
    },
  },
};
