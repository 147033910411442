<template>
  <v-container fill-height>
    <v-container
      class="pa-0 ma-0"
      fill-height
    >
      <v-row
        no-gutters
        style="height: 100%;"
        justify="center"
        align="center"
      >
        <v-col
          sm="8"
          md="4"
        >
          <v-row justify="center">
            <v-img
              src="@/assets/logowhite_pepsico.png"
              max-width="300"
              contain
              style="margin-bottom: 25px;"
              alt=""
            />
          </v-row>
          <v-card
            flat
            class="rounded-0"
          >
            <v-container fill-height>
              <v-row
                justify="center"
                align="center"
              >
                <v-col
                  cols="10"
                  sm="10"
                  md="10"
                >
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    style="margin: 36px 0;"
                    @submit.prevent="login()"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      dense
                      single-line
                      class="rounded-0"
                      required
                      :rules="emailRules()"
                      :label="$t('commonForm.email')"
                      :append-icon="'fas fa-envelope'"
                      @input="checkAlert()"
                    />
                    <v-text-field
                      v-model="password"
                      type="password"
                      outlined
                      dense
                      single-line
                      class="rounded-0"
                      required
                      :label="$t('commonForm.password')"
                      :append-icon="'fas fa-lock'"
                      @input="checkAlert()"
                    />
                    <v-alert
                      v-show="alert"
                      dense
                      text
                      icon="fas fa-exclamation-triangle"
                      color="error"
                    >
                      {{ $t('login.warningEmailPass') }}
                    </v-alert>
                    <v-row
                      justify="end"
                      style="padding-right: 12px;"
                    >
                      <v-btn
                        color="primary"
                        tile
                        elevation="0"
                        type="submit"
                        :disabled="isButtonDisabled"
                        :loading="loggingIn"
                      >
                        {{ $t('login.login') }}
                      </v-btn>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import authService from '../services/modules/auth';
import validationsMixin from '@/mixins/validations';
// import { mapActions } from 'vuex'


export default {
  name: 'Login',
  mixins: [validationsMixin],
  data() {
    return {
      valid: true,
      email: '',
      password: '',
      alert: false,
      loggingIn: false,
    };
  },
  computed: {
    isButtonDisabled: function() {
      return !(this.email !== '' && this.password !== '' && this.valid);
    },
  },
  mounted() {
    this.$store
        .dispatch('ui/loginBackground');
  },
  methods: {
    login() {
      this.loggingIn = true;
      authService.login(this.email, this.password)
          .then(({ data, headers }) => {
            // console.log('data', headers.authorization);
            const user = {
              name: data.user.name,
              created: data.user.created,
              role: data.user.role,
            };
            this.$store.commit('session/ADD_USER', user);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token-YPm0ZesF2yAuZvGn', headers.authorization);
            this.$router.push({ name: 'search' });
          })
          .catch((err) => {
            this.loggingIn = false;
            if (err.response) {
              if (err.response.status === 400 || err.response.status === 401 ) {
                this.alert = true;
              }
            }
          });
    },
    checkAlert() {
      if (this.alert) {
        this.alert = false;
      }
    },
  },
};
</script>
